/*
 * @Description: 菜单hook
 * @Author: Mars
 * @Date: 2023-12-06 11:41:39
 * @LastEditors: YuKi
 * @LastEditTime: 2024/04/12
 */
import { useRecoilState, useRecoilValue } from 'recoil';
import { IMenuItem, MenuType } from '@/services/user/types';
import menuState, { flattenMenuState } from '@/store/menu';
import { getPureMenuUrl } from '@/utils/common';

/** 菜单hook */
const useMenu = () => {
  const [menuData, setMenuData] = useRecoilState(menuState);
  const menu = menuData.menu;
  const microApps = menuData.microApps;
  /** 一维化菜单 */
  const flattenMenu = useRecoilValue(flattenMenuState);
  /**
   * @Author: Mars
   * @description: 根据路由获取菜单项
   * @param {string} url 路由地址
   * @return {*}
   */
  const getMenuItemByPath = (url: string) => {
    const microItem = getMicroAppInfoByUrl(url);
    if (microItem) {
      return flattenMenu.find(
        (it) => it.menuType === MenuType.page && getPureMenuUrl(it.menuUrl) === microItem.url && it.systemWebCode === microItem.microName,
      );
    } else return flattenMenu.find((it) => it.menuType === MenuType.page && getPureMenuUrl(it.menuUrl) === url);
  };

  /**
   * @Author: YuKi
   * @description: 刷新页面时，解决详情页（路径中存在/*）无法匹配到的问题
   * @param {string} url
   * @return {*}
   */
  const getMenuItemByAnyPath = (url: string): IMenuItem | undefined => {
    const item = getMenuItemByPath(url);
    if (item) {
      return item;
    } else {
      const pathname = url.replace(/\/[^/]*$/, '');
      if (pathname) {
        return getMenuItemByAnyPath(pathname);
      } else {
        return undefined;
      }
    }
  };

  /**
   * @Author: Mars
   * @description: 根据路由获取微应用信息
   * @param {string} url
   * @return {*}
   */
  const getMicroAppInfoByUrl = (url: string) => {
    const firstLevelUrl = /\/(\w+)\//.exec(url)?.[1];
    if (firstLevelUrl && microApps.some((it) => it.systemWebCode === firstLevelUrl)) {
      return { microName: firstLevelUrl, url: url.replace(`/${firstLevelUrl}`, '') };
    }
    return undefined;
  };

  /**
   * @Author: Mars
   * @description: 获取所有父级的keys
   * @param {IMenuItem} flattenMenu 一维化菜单
   * @param {IMenuItem} item 查找项
   * @return {*}
   */
  const getParentDirectory = (flattenMenu: IMenuItem[], item: IMenuItem) => {
    const keys: string[] = [];
    const parentItem = flattenMenu.find((it) => it.id === item.parentId);
    if (parentItem) {
      {
        if (parentItem.menuType === MenuType.directory) keys.push(parentItem.id);
      }
      keys.push(...getParentDirectory(flattenMenu, parentItem));
    }
    return keys;
  };

  return { menu, flattenMenu, getMicroAppInfoByUrl, microApps, setMenuData, menuData, getParentDirectory, getMenuItemByPath, getMenuItemByAnyPath };
};

export default useMenu;
