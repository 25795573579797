import { createRequest } from '@alpha-ess/utils';
import { isDev } from '@/config';

const request = createRequest({
  dev: isDev,
  noAuthPaths: ['/msg/myMsg/getHomeMsg', '/stable/user/login', '/stable/user/getUserAgreement', '/base/activity/checkSubmitPerm', '/base/activity/submit'],
  excludeBaseUrlList: ['/geocoding/v3', '/timezone/v1', '/v1/current_time'],
});

export default request;
