import { ISystemConfig } from '../types';

const systemConfigMockBaseData: ISystemConfig = {
  carouselNum: 4,
  dataCenter: [
    {
      url: 'https://chinacloud.alphaess.com/api/',
      label: 'register.domestic',
      key: 'CN',
    },
    {
      url: 'https://cloudweb6.alphaess.com/api/',
      label: 'register.overseas',
      key: 'SG',
    },
  ],
  copyRight: 'All rights reserved © Alpha ESS Co., Ltd.',
  filingNumber: '苏ICP备2021040727号-2',
};

export const getMockSystemConfig = (): Promise<ISystemConfig> => {
  return new Promise((resolve) => {
    setTimeout(resolve, 0, systemConfigMockBaseData);
  });
};
