/*
 * @Description: Not Found 页面
 * @Author: Mars
 * @Date: 2023-12-08 17:50:30
 * @LastEditors: Mars
 * @LastEditTime: 2023-12-20 09:26:06
 */
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

interface IProps {
  pageUrl?: string;
}
const NotFound = (props: IProps) => {
  const { pageUrl } = props;
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle={`页面未找到：${pageUrl}`}
      extra={
        <Button type="primary" onClick={() => navigate('/')}>
          返回首页
        </Button>
      }
    />
  );
};

export default NotFound;
