/*
 * @Description: 页面容器
 * @Author: Mars
 * @Date: 2023-12-06 17:06:12
 */
import { useLanguage } from '@alpha-ess/store';
import { Breadcrumb, Button, Card, Flex } from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { ComponentProps, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CUSTOMER_SERVICE_URL, NOT_SHOW_AI_BTN_URLS } from '@/components/SystemModals/components/ICustomerService';
import useMenu from '@/hooks/useMenu';
import { IMenuItem } from '@/services/user/types';
import { showAIState } from '@/store/ai';
import { breadState } from '@/store/bread';

/** 面板容器的拓展节点ID */
const PANEL_EXTRA_CONTAINER = 'micro-base-panel-header-extra';
/** 有面包屑页面的视窗高度 */
const PANEL_VIEW_HEIGHT = 'calc(100vh - 56px - 32px - 56px - 1px)';

/** 页面外容器，包含面包屑 */
const Panel = (props: { children?: ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t: tF } = useTranslation('clientFeedback');
  const { language } = useLanguage();
  const { flattenMenu, getMenuItemByPath } = useMenu();
  const bread = useRecoilValue(breadState);
  const showAI = useRecoilValue(showAIState);
  const preValue = useRef<ItemType[]>([]);
  // 是否显示AI拓展按钮
  const [showAIExtra, setShowAIExtra] = useState(false);

  /** 面包屑菜单内容 */
  const breadItems = useMemo(() => {
    const url = location.pathname;
    setShowAIExtra(NOT_SHOW_AI_BTN_URLS.includes(url) && showAI && url !== CUSTOMER_SERVICE_URL);
    if (bread && bread.length > 0) {
      // 如果有子应用传递的面包屑
      const breadItems = bread.map((it) => ({ title: it }));
      const parentUrl = getParentItemPath(url);
      const item = getMenuItemByPath(parentUrl);
      if (item) {
        return [...getBreadcrumbItems(flattenMenu, item, language), { title: item.menuNameDic[language] }, ...breadItems];
      }
      return breadItems;
    } else {
      const item = getMenuItemByPath(url);
      if (item) {
        return [...getBreadcrumbItems(flattenMenu, item, language), { title: item.menuNameDic[language] }];
      } else {
        return preValue.current;
      }
    }
  }, [location.pathname, flattenMenu, language, bread]);

  useEffect(() => {
    preValue.current = breadItems;
  }, [breadItems]);

  /**
   * @Author: Mars
   * @description: 按钮点击回调
   * @return {*}
   */
  const handleClick = () => {
    navigate(CUSTOMER_SERVICE_URL);
  };

  return (
    <Card
      style={{ display: 'flex', flexDirection: 'column', position: 'unset' }}
      styles={{
        body: {
          minHeight: PANEL_VIEW_HEIGHT,
          padding: 0,
        },
      }}
      title={<Breadcrumb items={breadItems} />}
      extra={
        <div id={PANEL_EXTRA_CONTAINER}>
          {showAIExtra && (
            <Button onClick={handleClick} type="text">
              <Flex align="center" gap={8}>
                <img src="/img/iCustomerService/earphone-icon.svg" />
                {tF('robotCusService.intCustomerService')}
              </Flex>
            </Button>
          )}
        </div>
      }
    >
      {props.children}
    </Card>
  );
};

/**
 * @Author: Mars
 * @description: 获取菜单项的父级面包屑配置
 * @param {IMenuItem} flattenMenu 一维化菜单
 * @param {IMenuItem} item 菜单项
 * @param {string} language 语言
 * @return {*}
 */
const getBreadcrumbItems = (flattenMenu: IMenuItem[], item: IMenuItem, language: string) => {
  const items: ItemType[] = [];
  const parentItem = flattenMenu.find((it) => it.id === item.parentId);
  if (parentItem) {
    items.unshift({ title: parentItem.menuNameDic[language] });
    items.unshift(...getBreadcrumbItems(flattenMenu, parentItem, language));
  }
  return items;
};

/**
 * @Author: Mars
 * @description: 获取父级路由
 * @param {string} url
 * @return {*}
 */
const getParentItemPath = (url: string) => {
  const parentUrl = url.replace(/\/[^/]+$/, '');
  return parentUrl;
};

export const PanelCard = (props: ComponentProps<typeof Card>) => {
  return (
    <Card
      style={{ display: 'flex', flexDirection: 'column', position: 'unset' }}
      styles={{
        body: {
          minHeight: PANEL_VIEW_HEIGHT,
          padding: 0,
        },
      }}
      {...props}
    />
  );
};

export default Panel;
