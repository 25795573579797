/*
 * @Description: Http请求模块
 * @Author: Mars
 * @Date: 2023-11-30 17:01:41
 * @LastEditors: Mars
 * @LastEditTime: 2023-12-18 16:13:07
 */
import { createHttp } from '@alpha-ess/utils';
import request from '@/utils/request';

const http = createHttp(request);

export default http;
