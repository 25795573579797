import { createStyles } from 'antd-style';

const useStyles = createStyles(({ css }) => {
  return {
    popover: css`
      .ant-popover-content {
        .ant-popover-inner {
          border-radius: 10px 10px 0 10px;
          background: linear-gradient(149.57deg, #f8d461 19.73%, #fabf00 70.12%, #fa9600 254.46%);
        }
      }
    `,
    tour: css`
      .ant-tour-content {
        transform: translateY(-80px);
        ::before {
          position: absolute;
          top: -110px;
          content: url('/img/iCustomerService/kitty-tour.png');
        }
        ::after {
          position: absolute;
          bottom: -48px;
          right: -12px;
          content: url('/img/iCustomerService/tour-arrow.svg');
        }
        .ant-tour-inner {
          background: radial-gradient(
            84.45% 477.75% at 23.83% 7.05%,
            rgb(255 255 255) 3.23%,
            rgb(254 221 170) 21.19%,
            rgb(255 183 47) 89.96%,
            rgb(255 240 212) 98.54%
          );
        }
      }
    `,
    tourContent: {
      color: 'rgba(86, 63, 51, 1)',
      fontWeight: 'bold',
    },
    mask: css`
      position: absolute;
      height: 100vh;
      width: 100vw;
      z-index: 998;
      background-color: rgba(0, 0, 0, 0.45);
    `,
    floatBtn: css`
      z-index: 999;
      cursor: pointer;
      position: fixed;
      box-shadow: none;
      padding: 0;
      bottom: 24px;
      right: 24px;
      opacity: 0.5;
      transition: opacity 0.2s;
      :hover {
        opacity: 1;
      }
    `,
  };
});

export default useStyles;
