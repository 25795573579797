/*
 * @Description: 应用入口文件
 * @Author: Mars
 * @Date: 2023-11-30 14:28:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-07-19 10:47:48
 */
import { useLanguage, useTheme } from '@alpha-ess/store';
import { Fallback } from '@alpha-ess/ui';
import { App as AntApp, ConfigProvider } from 'antd';
import { ThemeProvider } from 'antd-style';
import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { routeState } from '@/routes';

function App() {
  const routeStore = useRecoilValue(routeState);
  const { locale } = useLanguage();
  const { theme } = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider locale={locale}>
        <AntApp style={{ height: '100%' }}>
          <Suspense fallback={<Fallback fullscreen className="alpha-bg" />}>
            <RouterProvider router={routeStore} />
          </Suspense>
        </AntApp>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
