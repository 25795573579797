/*
 * @Description: 项目配置文件
 * @Author: Mars
 * @Date: 2023-11-20 09:51:50
 * @LastEditTime: 2024-07-23 13:36:36
 */
import { ISystemConfig } from '@/services/system/types';

const config = {
  /** 翻译文件地址 */
  localePath: import.meta.env.VITE_LOCALE_SRC,
  /** 语言列表 */
  languageList: [
    {
      label: '中文',
      value: 'zh-CN',
      symbolValue: 'zh',
    },
    {
      label: 'English',
      value: 'en-US',
      symbolValue: 'en',
    },
    {
      label: 'Deutsch',
      value: 'de-DE',
      symbolValue: 'de',
    },
    {
      label: '日本語',
      value: 'ja-JP',
      symbolValue: 'ja',
    },
    {
      label: 'Italiano',
      value: 'it-IT',
      symbolValue: 'it',
    },
    {
      label: 'Nederlands',
      value: 'nl-NL',
      symbolValue: 'nl',
    },
    {
      label: 'España',
      value: 'sp-SP',
      symbolValue: 'sp',
    },
    {
      label: 'Ελληνικά',
      value: 'el-GR',
      symbolValue: 'el',
    },
    {
      label: 'Français',
      value: 'fr-FR',
      symbolValue: 'fr',
    },
    {
      label: 'Polski',
      value: 'pl-PL',
      symbolValue: 'PL',
    },
    {
      label: 'Čeština',
      value: 'cs-CZ',
      symbolValue: 'cs',
    },
  ],
  /** 静态资源地址 */
  staticPath: import.meta.env.VITE_STATIC_RES_PREFIX,
  /** 调取时区的接口key */
  abstractTimeZoneAPIKey: '9236d662ce654d859056bdecae2e0d43',
  /** 文件下载前缀 */
  downloadUrl: import.meta.env.VITE_DOWNLOAD_PREFIX,
  /** GA key */
  gaTrackingId: 'G-B3RYFT09DQ',
  /** 系统id */
  systemId: import.meta.env.VITE_SYSTEM_ID,
  /** 系统配置 */
  systemConfig: {} as ISystemConfig,
  /** 百度地图key */
  baiduMapKey: '1cUeMmeZtmBlSBzxwKd5nmodlD8gInVk',
  /** 谷歌地图key */
  googleMapKey: import.meta.env.VITE_GOOGLE_MAP_KEY,
  /** 是否第三方登录 */
  thirdLogin: import.meta.env.VITE_THIRD_LOGIN === 'true',
};

/** 是否为开发环境 */
export const isDev = import.meta.env.DEV;

export default config;
