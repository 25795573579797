/*
 * @Description: 面包屑状态
 * @Author: Mars
 * @Date: 2023-12-28 13:44:47
 * @LastEditors: Mars
 * @LastEditTime: 2024-02-04 13:22:04
 */
import { atom } from 'recoil';

/** 面包屑状态 */
export const breadState = atom<string[]>({ key: 'breadState', default: [] });
