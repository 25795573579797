/*
 * @Description: 模块工具
 * @Author: Mars
 * @Date: 2023-12-05 09:36:44
 * @LastEditors: Mars
 * @LastEditTime: 2024-05-31 10:56:03
 */
import { Fallback } from '@alpha-ess/ui';
import { ComponentType, Fragment, Suspense, lazy } from 'react';
import Panel from '@/components/Panel';
import NotFound from '@/pages/Error/NotFound';
import { IMenuItem } from '@/services/user/types';

const modules = import.meta.glob<{ default: ComponentType<unknown> }>(['@/pages/**/index.tsx', '!**/components/**/index.tsx']);

/**
 * @Author: Mars
 * @description: 根据菜单路径获取对应模块代码
 * @param {IMenuItem} item
 * @return {*}
 */
export const getElement = (item: IMenuItem) => {
  if (item.componentUrl) {
    // 转换为实际地址
    const truePath = item.componentUrl.replace('@', '/src') + '/index.tsx';
    if (modules[truePath]) {
      const Element = lazy(modules[truePath]);
      // 根据拓展字段判断是否加载外部面板容器
      const noPanel = item.extra?.split('|').includes('noPanel');
      const noPadding = item.extra?.split('|').includes('noPadding');
      const WarpElement = noPanel ? Fragment : Panel;
      return (
        <WarpElement>
          <div style={{ height: '100%', padding: noPadding || noPanel ? 0 : 24 }}>
            <Suspense fallback={<Fallback showFullParent />}>
              <Element />
            </Suspense>
          </div>
        </WarpElement>
      );
    }
  }
  return (
    <Panel>
      <NotFound pageUrl={item.componentUrl} />
    </Panel>
  );
};
