/*
 * @Description: 系统设置模块接口
 * @Author: Mars
 * @Date: 2024-01-29 17:54:07
 * @LastEditors: Mars
 * @LastEditTime: 2024-01-29 18:53:16
 */
import http from '@/utils/http';
import { getMockSystemConfig } from './mock/systemConfig.mock';
import * as Types from './types';

export default {
  async getSystemConfig() {
    return await getMockSystemConfig();
    return await http.get<Types.ISystemConfig>({ url: '/userCenter/fileOperation/fileDownload' });
  },
};
