/*
 * @Description: 语言工具模块
 * @Author: Mars
 * @Date: 2023-11-30 17:02:12
 * @LastEditors: Mars
 * @LastEditTime: 2024-03-21 16:34:35
 */
import { getBrowserLanguage, getLocalLanguage, setLocalLanguage } from '@alpha-ess/utils';
import config from '@/config';

/**
 * @Author: Mars
 * @description: 获取当前语言-long code
 * @return {*}
 */
export function getLanguage(): string {
  let language = getLocalLanguage() || getBrowserLanguage();
  // 如果localStorage中的语言或者浏览器语言不在列表内，设置成英文
  if (!config.languageList.some((it) => it.value === language)) {
    language = 'en-US';
  }
  setLocalLanguage(language);
  return language;
}
