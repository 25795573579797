/*
 * @Description: 微前端工具
 * @Author: Mars
 * @Date: 2023-12-07 11:46:50
 * @LastEditors: Mars
 * @LastEditTime: 2024-05-29 13:48:47
 */
import microApp from '@micro-zoe/micro-app';
import { IMenuItem } from '@/services/user/types';
import { getPureMenuUrl } from './common';

/**
 * @Author: Mars
 * @description: 获取跳转地址
 * @param {IMenuItem} item
 * @return {*}
 */
export const getNavigateUrl = (item: IMenuItem) => {
  return `${item.systemWebCode}${getPureMenuUrl(item.menuUrl)}`;
};

/**
 * @Author: Mars
 * @description: 设置子应用数据
 * @param {string} name
 * @param {Record} data
 * @param {*} unknown
 * @return {*}
 */
export const microSetData = (name: string, data: Record<PropertyKey, unknown>) => {
  microApp.setData(name, data);
};

/**
 * @Author: Mars
 * @description: 获取子应用的url，支持JSON版本
 * @param {string} url
 * @return {*}
 */
export const getMicroAppUrl = (url: string): string => {
  const urlKey = import.meta.env.VITE_MICRO_APP_URL_KEY;
  if (urlKey) {
    try {
      const urlObject = JSON.parse(url);
      return urlObject[urlKey] || '';
    } catch (e) {
      return url;
    }
  } else {
    // 如果没有配置urlKey，直接返回
    return url;
  }
};
