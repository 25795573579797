/*
 * @Description: 自定义请求hook
 * @Author: Mars
 * @Date: 2023-12-08 14:49:45
 */
import { ResponseCode, ResponseError, removeToken } from '@alpha-ess/utils';
import { useRequest } from 'ahooks';
import { Options, Plugin, Service } from 'ahooks/lib/useRequest/src/types';
import { App } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CustomOptions<TData, TParams extends any[]> = Omit<Options<TData, TParams>, 'onError'> & {
  onError?: (e: ResponseError, params: TParams) => void;
  /** 隐藏显示错误提示 */
  hideError?: boolean;
  /** 禁用错误判断 */
  disableError?: boolean;
};

/**
 * @Author: Mars
 * @description: 自定义请求
 * @param {*} TData
 * @param {*} TParams
 * @return {*}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useFetch = <TData, TParams extends any[]>(
  service: Service<TData, TParams>,
  options?: CustomOptions<TData, TParams>,
  plugins?: Plugin<TData, TParams>[],
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { message } = App.useApp();
  const rewriteOptions: Options<TData, TParams> = {
    ...options,
    onError: (error, params) => {
      const err = error as unknown as ResponseError;
      if (!options?.disableError) {
        // 如果登录状态失效，退出登录
        if ([ResponseCode.LOGIN_EXPIRED, ResponseCode.TOKEN_IS_NULL, ResponseCode.ILLEGAL_LOGIN].includes(err.code)) {
          removeToken();
          if (location.pathname !== '/login') {
            navigate('/login');
            return;
          }
        }
      }
      // 如果调用方设置了onError，调用相应逻辑；否则，显示错误消息
      if (!options?.hideError) {
        message.error(err.msg);
      }
      if (options?.onError) {
        options.onError(err, params);
      }
    },
  };
  return useRequest(service, rewriteOptions, plugins);
};

export default useFetch;
