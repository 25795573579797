import http from '@/utils/http';
import * as Types from './types';

export default {
  async getAdvertisement() {
    return await http.get<Types.IAdvertisementInfo>({ url: '/marketlearn/activity/getAdvertisementPopUp' });
  },
  /**
   * @Author: YuKi
   * @description: 禁用弹窗
   * @param {Types} sysSn
   */
  async banPopUp(data: Types.IBanPopUpParams) {
    return await http.post({ url: '/marketlearn/client/activity/banPopUp', data });
  },

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 获取系统更新通知
   * @return {*}
   */
  async getSystemUpdateLog() {
    return await http.get<Types.IGetSystemUpdateLogRes>({ url: '/msg/sysMsg/getLatestSystemUpdateLog' });
  },
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 记录用户已读更新日志
   * @param {object} data
   * @return {*}
   */
  async modifyUpdateLogStatus(data: { key: string; flag?: number }) {
    return await http.post({
      url: '/msg/sysMsg/recordUpdateLog',
      data,
    });
  },
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 获取需要修改wifi初始密码的设备信息
   * @param {object} data
   * @return {*}
   */
  async getEditWifiPwdInfo() {
    return await http.get<Types.IGetEditWifiPwdInfo>({ url: '/iterate/sysSet/getEditWifiPwdInfo' });
  },
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 批量修改用户设备wifi密码
   * @param {object} data
   * @return {*}
   */
  async batchUpdateWifiInfo(data: Types.ISaveWifiSn) {
    return await http.put({ url: '/iterate/sysSet/batchUpdateWifiInfo', data });
  },

  /**
   * @Author: Mars
   * @description: 获取智能客服提示
   * @return {*}
   */
  async getAiServiceControl() {
    return await http.get<Types.IGetAiServiceControlResp>({ url: '/ai/customerService/getControlParams' });
  },
};
