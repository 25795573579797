import CryptoJS from 'crypto-js';
import { addPageEtag, getPageEtag } from './localStorage';

/**
 * @Author: Mars
 * @description: 去除路由后面的 /*
 * @param {string} menuUrl
 * @return {*}
 */
export const getPureMenuUrl = (menuUrl: string) => {
  if (menuUrl.endsWith('/*')) return menuUrl.slice(0, -2);
  return menuUrl;
};

/**
 * @Author: Mars
 * @description: 单位转换
 * @param {number} value 值
 * @param {string} unit 单位
 * @param {number} decimal 精度
 * @return {*}
 */
export const unitConversion = (value = 0, unit?: string, decimal?: number) => {
  let finalUnit = unit;
  let finalValue = value;
  if (!unit) {
    return { finalUnit: unit, finalValue: value };
  }
  const tmp = Number(value / 1000);
  if (Math.abs(tmp) > 1000) {
    if (unit === 'kWh') finalUnit = 'GWh';
    if (unit === 'kW') finalUnit = 'GW';
    if (unit === 'kg') finalUnit = 'kt';
    finalValue = Number(tmp / 1000);
  } else if (Math.abs(tmp) > 1) {
    if (unit === 'kWh') finalUnit = 'MWh';
    if (unit === 'kW') finalUnit = 'MW';
    if (unit === 'kg') finalUnit = 't';
    finalValue = tmp;
  } else {
    finalUnit = unit;
    finalValue = value;
  }
  if (decimal) finalValue = Number(formatValueDecimal(finalValue, decimal));
  finalValue = formatDecimalNumber(finalValue);
  return { finalUnit, finalValue };
};

/**
 * @description: 处理数值精度
 * @param {number} num
 * @return {*}
 * @LastEditors: April
 */
export const formatDecimalNumber = (num: number) => {
  num = num ?? 0;
  if (Number.isInteger(num) || (Math.round(num * 100) / 100).toFixed(2).endsWith('00')) {
    return Number(num.toFixed(0));
  } else {
    return Number((Math.round(num * 100) / 100).toFixed(2));
  }
};
/**
 * @Author: Mars
 * @description:
 * @param {*} value 数值
 * @param {*} digit 精度
 * @return {*}
 */
export const formatValueDecimal = (value = 0, decimal = 2) => {
  // 如果本身不包含小数点，不进行处理
  if (Number.isInteger(value)) {
    return value.toString();
  }

  return value.toFixed(decimal);
};
/**
 * @Author: Mars
 * @description: 复制内容到剪切板
 * @param {string} textToCopy
 * @return {*}
 */
export const copyToClipboard = async (textToCopy: string) => {
  if (window.navigator.clipboard && window.isSecureContext) {
    await window.navigator.clipboard.writeText(textToCopy);
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;

    textArea.style.position = 'absolute';
    textArea.style.left = '-999999px';

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
};

/**
 * @Author: Mars
 * @description: 判断是否需要刷新页面
 * @param {string} url
 * @return {*}
 */
export const needRefresh = async (url: string): Promise<boolean> => {
  const etag = getPageEtag(url);
  const resp = await fetch(`${url}`, { headers: { 'If-None-Match': etag ?? '' } });
  if (resp.ok) {
    if (resp.status === 200) {
      const respEtag = resp.headers.get('Etag') || '';
      addPageEtag(url, respEtag);
      // 如果原本有etag，则代表需要刷新
      return Boolean(etag);
    } else {
      return false;
    }
  } else {
    return false;
  }
};

/**
 * @Author: April
 * @description: 哈希512加密
 * @param {string} content
 * @return {*}
 */
export const hashStringWithSHA512 = (content: string) => {
  const sha1Hash = CryptoJS.SHA512(content);
  return sha1Hash.toString(CryptoJS.enc.Hex);
};
