/*
 * @Description: 语言状态提供者
 * @Author: Mars
 * @Date: 2024-02-05 11:16:24
 * @LastEditors: Wayne Hu
 * @LastEditTime: 2024/04/10 19:41:48
 */
import { useLanguage } from '@alpha-ess/store';
import { getShortCode } from '@alpha-ess/utils';
import { ReactNode, useEffect, useState } from 'react';
import config from '@/config';
import { getLanguage } from '@/utils/language';

interface LanguageProviderProps {
  /** url中的语言key，默认为'lang' */
  languageKey?: string;
  children?: ReactNode;
}
/**
 * @Author: Mars
 * @description: 获取url中传入的语言并初始化
 * @param {LanguageProviderProps} props
 * @return {*}
 */
const LanguageProvider = (props: LanguageProviderProps) => {
  const { languageKey = 'lang', children } = props;
  const [isInit, setIsInit] = useState(true);
  const { setLanguage } = useLanguage();
  useEffect(() => {
    const params = new URL(location.href).searchParams;
    const paramsShortLang = getShortCode(params.get(languageKey) || getLanguage());
    const langItem = config.languageList.find((it) => it.symbolValue === paramsShortLang);
    if (langItem) {
      setLanguage(langItem.value);
    } else {
      setLanguage(getLanguage());
    }
    setIsInit(false);
  }, []);
  return <>{isInit ? null : children}</>;
};

export default LanguageProvider;
